<template>
    <div class="p-4 flex justify-between items-center">
        <div>
            <span>{{ data.name }}</span>
        </div>
        <div>
            <span>{{ data.relation }}</span>
        </div>
        <div class="flex space-x-2">
            <div class="flex items-center whitespace-no-wrap" :class="isDisabled ? 'text-grey' : 'text-success cursor-pointer hover:font-semibold'" @click="invite(data.mid)">
                <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                <span>{{ $t('apptMgmt.invite') }}</span>
            </div>
            <div class="flex items-center whitespace-no-wrap" :class="isDisabled ? 'text-grey' : 'text-primary cursor-pointer hover:font-semibold'" @click="callPatient(data.mid, data.pid)" v-if="data.online">
                <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                <span>{{ $t('components.call.call') }}</span>
            </div>
            <div class="flex items-center text-grey whitespace-no-wrap" v-else>
                <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                <span>{{ $t('components.call.offline') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { throttle } from 'lodash'
export default {
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
        qid: {
            type: Number,
            default: 0,
            required: false,
        },
        sn_id: {
            type: Number,
            default: 0,
            required: false,
        },
        isTransfer: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        isDisabled() {
            return this.isTransfer > 0 && this.qid == 0
        },
    },
    methods: {
        // 打給民眾
        callPatient: throttle(async function (mid, pid) {
            if (this.isDisabled) return
            const payload = { ring: 1, direction: 1 }
            payload.mid = mid
            payload.pid = pid
            payload.is_doctor = 0
            if (this.qid > 0) payload.queue_id = this.qid
            if (this.sn_id > 0) payload.sn_id = this.sn_id
            if (this.isTransfer) payload.transfer = 1

            const res = await this.$store.dispatch('call_p2p', payload)

            // 跳轉
            const targetPath = '/emr2/' + pid + '/' + this.qid
            if (res && pid > 0 && this.$router.currentRoute.fullPath != targetPath) {
                this.$router.push(targetPath)
            } else {
                if (!this.isTransfer) this.$store.dispatch('updateDoctorStatus', 1000)
            }

            this.$emit('contact')
        }, 3000),
        invite(mid) {
            if (this.isDisabled) return
            const payload = {
                mid: mid,
                is_doctor: 0,
            }
            this.$bus.$emit('invite', payload)

            this.$emit('contact')
        },
    },
}
</script>
