<template>
    <div class="px-4 py-2 flex justify-between items-center w-full">
        <div class="truncate" :style="'max-width: ' + (windowWidth - 200) + 'px'">
            <span class="mr-2 text-lg text-primary" v-if="data.online && data.online_status == 1000">●</span>
            <span class="mr-2 text-lg text-warning" v-else-if="data.online && data.online_status == 2000">●</span>
            <span class="mr-2 text-lg text-danger" v-else-if="data.online && data.online_status == 3000">●</span>
            <span class="mr-2 text-lg invisible" style="color: #626262" v-else>●</span>
            <span>{{ data.name }}{{ data.title }}</span>
        </div>
        <div class="flex-shrink-0">
            <div class="flex items-center text-grey whitespace-no-wrap" v-if="!data.online">
                <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                {{ $t('components.call.offline') }}
            </div>
            <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="callDoctor()" v-else-if="data.online_status == 1000">
                <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                <span>{{ $t('apptMgmt.call') }}</span>
            </div>
            <div class="flex items-center text-warning whitespace-no-wrap" v-else-if="data.online_status == 2000">
                <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                {{ $t('components.call.busy') }}
            </div>
            <div class="flex items-center text-danger whitespace-no-wrap" v-else-if="data.online_status == 3000">
                <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                {{ $t('components.call.leave') }}
            </div>
        </div>
    </div>
</template>
<script>
import { transfer } from '@/api/user'
import { throttle } from 'lodash'
import { mapState } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
        pid: {
            type: Number,
            default: 0,
            required: false,
        },
        qid: {
            type: Number,
            default: 0,
            required: false,
        },
        sn_id: {
            type: Number,
            default: 0,
            required: false,
        },
        isTransfer: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        ...mapState(['windowWidth']),
    },
    methods: {
        // 打給醫師
        callDoctor: throttle(async function () {
            if (this.isTransfer && this.qid > 0) this.transfer()

            const payload = { ring: 1, direction: 1 }
            payload.mid = this.data.mid
            payload.is_doctor = 1
            if (this.pid > 0) payload.pid = this.pid
            if (this.qid > 0) payload.queue_id = this.qid
            if (this.sn_id > 0) payload.sn_id = this.sn_id
            if (this.isTransfer) payload.transfer = 1
            const res = await this.$store.dispatch('call_p2p', payload)

            // 跳轉
            const targetPath = '/emr2/' + this.pid + '/' + this.qid
            if (res && this.pid > 0 && this.$router.currentRoute.fullPath != targetPath) {
                this.$router.push(targetPath)
            } else {
                if (!this.isTransfer) this.$store.dispatch('updateDoctorStatus', 1000)
            }

            this.$emit('call')
        }, 3000),
        // 轉接醫師
        transfer() {
            const notiPayload = { title: this.$t('emr.transferTip') }
            this.$common.notify(notiPayload)
            const payload = {
                pid: this.pid,
                sdid: this.data.did,
                queue_id: this.qid,
            }
            transfer(payload)
        },
    },
}
</script>
