<template>
    <div class="p-3 w-fit flex justify-between items-start space-x-4 whitespace-no-wrap text-sm sm:text-base" :style="itemStyle">
        <!-- <div class="flex justify-between items-center space-x-2">
            <span>#{{ data.room_id }}</span>
            <span class="text-primary cursor-pointer hover:font-semibold" v-if="!here" @click="call(data.participants)">進入視訊房間</span>
            <span v-else>您已在此視訊房間</span>
        </div> -->
        <!-- <div v-for="(participant, index) in data.participants" :key="index">
            <span>{{ participant.name }}已進入房間</span>
        </div> -->
        <!-- <div class="flex justify-between space-x-4">
            <span>#{{ data.room_id }} {{ data.participants.length }}人在房間</span>
            <span class="text-primary cursor-pointer hover:font-semibold" @click="call(data.participants)">加入</span>
        </div> -->
        <!-- <div class="flex justify-between space-x-4">
            <span>房間共{{ data.participants.length }}人</span>
            <span class="text-primary cursor-pointer hover:font-semibold" @click="call(data.participants)">加入</span>
        </div> -->
        <div class="leading-normal">
            <p v-for="(participant, index) in data.participants" :key="index">
                <span :class="participant.pid > 0 ? 'text-primary cursor-pointer' : ''" @click="toMr(participant.pid, data.queue_id)">{{ participant.name }}</span>
                <span>已進視訊</span>
            </p>
        </div>
        <div>
            <!-- <span class="text-danger cursor-pointer" @click="call(data.participants)">加入</span> -->
            <!-- <vs-button class="cd-button-2w rounded-sm text-base" color="danger" type="border" @click="call(data.participants)">加入</vs-button> -->
            <vs-button class="cd-button-2w rounded-sm text-base" color="danger" type="border" @click="call(data.participants)" v-if="!here">加入</vs-button>
            <span v-else>通話中</span>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { throttle } from 'lodash'
export default {
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
    },
    computed: {
        ...mapState(['did', 'roomList', 'windowWidth']),
        here() {
            const check = _.find(this.data.participants, { did: this.did })
            return check ? true : false
        },
        itemStyle() {
            const style = this.windowWidth > 576 ? 'min-width: 250px;' : 'min-width: 200px;'
            // if (this.data.participants.length > 0) style += ' background-color: #fee;'
            return style
        },
    },
    methods: {
        call: throttle(async function (participants) {
            if (participants.length == 0) return
            const { mid, pid } = participants[0]
            const payload = {
                ring: 0,
                direction: 0,
                mid: mid,
                is_doctor: pid > 0 ? 0 : 1,
                pid: pid,
                room_id: this.data.room_id,
                queue_id: this.data.queue_id,
                sn_id: this.data.sn_id,
            }
            const res = await this.$store.dispatch('call_p2p', payload)

            if (res) {
                this.toMr(pid)
            } else {
                this.$store.dispatch('updateDoctorStatus', 1000)
            }

            this.$emit('close')
        }, 3000),
        toMr(pid = 0) {
            if (pid > 0 && this.$router.currentRoute.fullPath != '/emr2/' + pid + '/' + this.data.queue_id) this.$router.push('/emr2/' + pid + '/' + this.data.queue_id)
        },
    },
}
</script>
